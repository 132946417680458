
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "overview",
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Pages", "Profile"]);
    });
  },
});
